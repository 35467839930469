<template>
  <div class="home">
    <vue-headful title="Goodyear Ventures" description="Goodyear Ventures fuels the future of mobility by partnering and investing in seed-to-growth-stage startups in emerging mobility technology." url="https://www.goodyearventures.com/" ></vue-headful>
    <div class="page-background"></div>
    <section class="main-section">
      <b-container>
        <b-row>
          <b-col sm=12 md="12" lg="4" class="desc">
            <h1>120 Years of Innovation.</h1>
          </b-col>
          <b-col sm=12 md="12" lg="4" class="desc">
            <h1>Visionary Start-ups.</h1>
          </b-col>
          <b-col sm=12 md="12" lg="4" class="desc">
            <h1 class="last gy-border">Future of mobility.</h1>
          </b-col>
        </b-row>
      </b-container>
      <div class="wingfoot-scroll-wrapper">
        <img class="wingfoot" src="../assets/images/goodyear-wingfoot.svg" alt="Goodyear Wingfoot icon"/>
        <!-- <div class="scroll-text">scroll</div> -->
      </div>
    </section>
    <!-- <div class="floating-nav-wrapper">
       <FloatingNav />
    </div> -->
    <div id="home-body-section">
      <About/>
      <Partners/>
      <b-container><div class="separator"></div></b-container>
      <Focus/>
      <b-container><div class="separator"></div></b-container>
      <Portfolio/>
      <b-container><div class="separator"></div></b-container>
      <News/>
      <b-container><div class="separator"></div></b-container>
      <Team/>
    </div>

  </div>
</template>

<script>
//import axios from 'axios'
import About from './Home/About'
import Focus from './Home/Focus'
import Partners from './Home/Partners'
import Portfolio from './Home/Portfolio'
import News from './Home/News'
import Team from './Home/Team'
// import FloatingNav from './Home/FloatingNav'
export default {
  name: 'Home',
  components: {
    About,
    Focus,
    Partners,
    Portfolio,
    News,
    Team
    // FloatingNav
  },
  data () {
    return {
      windowHeight: 0,
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowHeight)
      window.addEventListener('resize', this.getWindowWidth)

      this.getWindowHeight()
      this.getWindowWidth()
    })
  },
  methods: {
    getWindowHeight () {
      var body = document.body,
      html = document.documentElement;

      var height = Math.max( body.scrollHeight, body.offsetHeight, 
                            html.clientHeight, html.scrollHeight, html.offsetHeight );

      this.windowHeight = height
    },
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowHeight)
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-position: 10% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  background-image: url('../assets/images/background/goodyear-blimp-md.png');
  background-attachment: scroll;
}
/*.home {
  background-color: #fff;
}*/
.page-background {
  /*background-position: 10% 50%;*/
  background-size: cover;
  background-repeat: no-repeat;
  /*position: fixed;*/
  position: absolute;
  top: 0;
  bottom: 0;
  /*left: 0;*/
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('../assets/images/background/goodyear-blimp.png');
}
.container {
  padding: 24px;
}
.btn {
  min-width: 100%;
}
/* @keyframes bounce {
  from {
    top: calc(100vh - 100px);
  }
  to {
    top: calc(100vh - 120px);
  }
} */
.wingfoot-scroll-wrapper {
  position: absolute;
  left: 48%;
  top: calc(100vh - 38px);

  /* animation-name: bounce;
  animation-delay: 0.1s;
  animation-direction: alternate;
  animation-duration: 0.65s;
  animation-iteration-count: infinite; */
}
.wingfoot {
  height: 65px;
}
.scroll-text {
  width: 75px;
  margin-left: -30px;
  color: #FEDA00;
  font-size: 1.1rem;
  /* background-color: rgba(0,0,0,.75);
  border-radius: 20px; */
  padding: 3px;
  text-align: center;
}
.floating-nav-wrapper {
  display: block;
  position: sticky;
  top: 0;
  background-color: rgba(0,0,0,.75);
  padding: 16px 70px 4px 70px;
  margin-top: 17px;
  z-index: 100;
}
section {
  background-color: #fff;
  /*position: relative;*/
}
.desc {
  text-align: center;
  margin: 5px 0px;
}
.subheader{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.subpara {
  font-size: 18px;
  letter-spacing: 1px;
}
.main-section {
  height: calc(100vh - 117px);
  background-color: transparent;
  /*border-bottom: 2px solid blue;*/
}
.main-section .container {
  padding-top: 200px;
}
.main-section h1 {
  font-size: 22px;
  color: #fff;
  display: inline;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.main-section h1.last {
  font-weight: bold;
}
.home-promo {
  text-align: left;
  padding: 15px 15px 5px 15px;
 /* border: 1px solid red;*/
  margin-bottom: 15px;
  /*min-height: 275px;*/
}
.home-promo.long {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 75px;
}
.home-promo h3 {
  margin-bottom: 15px;
}


@media (min-width: 445px) {
  .btn {
    min-width: 225px;
  }
  .wingfoot-scroll-wrapper {

  }
  .wingfoot {
    
  }
}
@media (max-width: 500px) {
  body, .page-background {
    background-position: 65% 50%;
    background-image: url('../assets/images/background/goodyear-blimp-xs.png');
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  body, .page-background {
    background-position: 64% 50%;
    background-image: url('../assets/images/background/goodyear-blimp-sm.png');
  }
}
@media (max-width: 767px) {
  .home-promo {
    margin-bottom: 25px;
  }
}
/*@media (max-width: 805px) {
  body {
    background-position: 70% 50%;
  }
}*/
@media (min-width: 767px) and (max-width: 991px) {
  body, .page-background {
    background-position: 70% 50%;
    background-image: url('../assets/images/background/goodyear-blimp-mdv2.png');
  }
}
@media (max-width:992px) {
  .main-section .container {
    padding-top: 75px;
  }
  .main-section h1 {
    background-color: rgba(0,0,0,.75);
  }
  .subheader::before { 
    display: block; 
    content: " "; 
    margin-top: -254px; 
    height:254px; 
    visibility: hidden; 
    pointer-events: none;
  }
  .home-promo {
    text-align: center;
  }
}

@media (min-width:992px) {
  .home-promo.long {
    width: 880px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media (min-width: 992px)  {
  
}
@media (max-width: 1000px) {
  .floating-nav-wrapper {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {

}
@media (min-width: 1201px)  {

}
@media (min-width: 1281px) and (max-width: 1600px) {
  body, .page-background {
    background-position: 70% 50%;
    background-image: url('../assets/images/background/goodyear-blimp-lg.png');
  }
}
@media (min-width: 1601px) {
  body, .page-background {
    background-position: 70% 50%;
    background-image: url('../assets/images/background/goodyear-blimp.png');
  }
}

</style>

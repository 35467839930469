<template>
  <section id="team" class="team-section">
      <b-container>
        <b-row class="d-flex justify-content-center mb-5">
          <b-col sm="12">
            <h2 class="subheader">Our Team</h2>
          </b-col>
          <b-col sm="12" class="d-flex justify-content-xs-center justify-content-md-end team-button-wrapper">
            <a class="btn gy-primary" href="/team" variant="primary">Meet The Team ⟶</a>
          </b-col>
          <b-col sm="12" md="4">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/contacts/erin-spring.png" alt="Erin Spring headshot">
              <div class="card-body">
                <h4 class="card-title">Erin Spring</h4>
                <p class="card-text">Senior Director, Material Science</p>
                <a class="btn gy-primary" href="https://www.linkedin.com/in/erin-spring-0737315" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/contacts/abhijit-ganguly.jpg" alt="Abhijit Ganguly headshot">
              <div class="card-body">
                <h4 class="card-title">Abhijit Ganguly</h4>
                <p class="card-text">Managing Director, Goodyear Ventures</p><br/>
                <a class="btn gy-primary" href="https://www.linkedin.com/in/abhijit-ganguly-6a823a12/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col sm="12" md="4">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/contacts/chitti-raju.png" alt="Chitti Raju headshot">
              <div class="card-body">
                <h4 class="card-title">Chitti Raju</h4>
                <p class="card-text">Senior Corporate Venture Capital Associate</p><br/>
                <a class="btn gy-primary" href="https://www.linkedin.com/in/chitti-raju/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/contacts/chris-varley.jpg" alt="Chris Varley headshot">
              <div class="card-body">
                <h4 class="card-title">Chris Varley</h4>
                <p class="card-text">Principal, Goodyear Ventures</p><br/>
                <a class="btn gy-primary" href="https://www.linkedin.com/in/cvarley" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/contacts/patrick-culhane.png" alt="Patrick Culhane headshot">
              <div class="card-body">
                <h4 class="card-title">Patrick Culhane</h4>
                <p class="card-text">Principal, Goodyear Ventures</p><br/>
                <a class="btn gy-primary" href="https://linkedin.com/in/patrick-culhane-584b1764/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="d-flex justify-content-xs-center justify-content-md-end team-button-wrapper mb-5">
          <a class="btn gy-primary" href="/team" variant="primary">Meet the team ⟶</a>
        </b-row> -->
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'Team',
  data: () => ({
    logo: require('@/assets/images/contacts/abhijit-ganguly.jpg')
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.team-section {
  margin-bottom: 25px;
}
.subheader {
  margin-bottom: 80px;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.card {
  border: none;
  margin: 0 auto;
  /*box-shadow: 5px 10px 18px rgb(0,0,0, .2);
  -webkit-box-shadow: 5px 10px 18px rgb(0,0,0, .2);*/
}
.card img.card-img-top {
  width: 225px;
  height: 225px;
  margin-left: 1rem;
  border-radius: 0px;
  margin: 0 auto;
}
.card .card-body {
  padding: 1rem;
  text-align: center;
 /* margin: 0 auto;*/
}
.card .card-title {
  font-weight: bold;
}
.card-text {
  height: 50px;
}
.btn.gy-primary {
  color: #000;
  text-transform: none;
}
br {
  display: none;
}

p {
  margin-bottom: 0 !important;
}

.team-button-wrapper {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 50px;
  }

@media (max-width: 767px) {
  .card {
    margin-bottom: 100px;
  }
  .card img.card-img-top {
    margin: 0 auto;
  }
  .card-body {
    text-align: center;
  }
  .team-button-wrapper {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  /*.card {
    width: 20rem;
  }*/
}
@media (min-width: 768px) and (max-width:992px){
  .card img.card-img-top {
    margin-left: 0px;
  }
}
@media (max-width:992px) {
  .card img.card-img-top {
    max-width: 100%;
  }
  .card .card-body {
    padding: 0px;
  }
  .btn.gy-primary {
    padding: .375rem .5rem;
    min-width: 100%;
  }
  .team-button-wrapper {
    margin-top: 50px;
  }
}
@media (min-width:993px) and (max-width: 1199px) {
  br {
    display: inline;
  }
  .team-button-wrapper {
    margin-top: 50px;
  }
}
</style>

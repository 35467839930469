<template>
  <div id="app">
    <div class="global-header"><Header/></div>
    <!-- <div v-if="this.currRoute == '/'"><Header/></div> -->
    <!-- <div v-if="this.currRoute == '/team'"><TeamHeader/></div> -->
    <router-view/>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/Header'
// import TeamHeader from '@/components/TeamHeader'
import Footer from '@/components/Footer'

export default {
  name: "App",
  components: { Header, Footer },

  data () {
    return {
      currRoute: window.location.pathname
    }
  }
};
</script>
<template>
  <section id="partner" class="partner-section">
      <b-container>
        <b-row>
          <b-col>
            <h3 class="subheader">A Partnership with Goodyear Means More than Money</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="home-promo">
              <h3>Deep Expertise and Presence</h3>
              <p>Since its inception, Goodyear products have been closely tied to milestones in human history - from origins of the first transport fleets, putting tires on the moon, to intelligent tires. Today, Goodyear's presence is global, operating and selling its products – including about 160 million tires annually – in nearly every country around the world.</p>
            </div>
          </b-col>

          <b-col sm="12" md="6">
            <div class="home-promo">
              <h3>120 Years of Innovation</h3>
              <p>Innovation has been at the core of what we've done for more than 100 years. It's the force driving Goodyear's contributions to the world of mobility, exemplified by our products, our services, and our embrace of the new.</p>
            </div>
          </b-col>

          <b-col sm="12" md="12">
            <div class="home-promo long">
              <h3>How We Support Startups</h3>
              <p>A partnership with Goodyear means much more than capital. Here are some of our core assets that we are leveraging to support startups:</p>
              <ol>
                <li>Supporting startups with Goodyear’s iconic brand </li>
                <li>Retail and services footprint supporting autonomous and electric vehicles</li>
                <li>Intelligent tires supporting autonomous and electric vehicles</li>
                <li>Product and solutions design for new and emerging OEMs in the mobility space</li>
              </ol>
              <p>... and much more.</p><br/>
              <br/>
              <div class="text-center">
                <h3>Learn More About Goodyear</h3>
                <p>Get a glimpse of our highlights or dig into a detailed history of The Goodyear Tire & Rubber Company.</p>
                <a class="btn gy-primary" href="https://corporate.goodyear.com/en-US/about/history.html" target="_blank" variant="primary">See History Timeline</a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'Partners'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.partner-section .subheader {
  margin-bottom: 55px;
  margin-top: 10px;
  font-size: 2.0rem;
}
.subheader{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.partner-section ol {
  text-align: left;
  margin: 0 auto;
  display: inline-block;
}
.btn.gy-primary {
  color: #000;
  text-transform: none;
}
.home-promo.long {
  margin-top: 7px;
  margin-bottom: 50px;
}
.home-promo {
  text-align: left;
  padding: 15px 15px 5px 15px;
 /* border: 1px solid red;*/
  margin-bottom: 15px;
  /*min-height: 275px;*/
}
.home-promo.long {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 75px;
}
.home-promo h3 {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .home-promo {
    margin-bottom: 25px;
  }
}
@media (max-width:992px) {
  .home-promo {
    text-align: center;
  }
}
@media (min-width:992px) {
  .home-promo.long {
    width: 880px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
</style>

<template>
  <section id="focus" class="focus-section">
      <b-container>
        <b-row>
          <b-col>
            <h2 class="subheader">Our Investment Focus</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <p>
              We value the entrepreneurs solving the most pressing challenges in mobility.<br>
              Are you improving mobility in one or more of the following ways?<br>
              <strong>If yes, we certainly want to meet you.</strong>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <img class="cases-image" src="../../assets/images/focus/CASES.png" alt="CASES: Convenient, Accessible, Sustainable, Efficient, Safe" />
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'Focus'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.focus-section {
  text-align: center;
}
.subheader {
  margin-bottom: 80px;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.cases-image {
  margin: 50px 0px;
  width: 100%;
}
/* .card {
  color: #fff;
  border-radius: 0px;
  border: 0px;
  margin-bottom: 20px;
}
.card-img {
  border-radius: 0px;
}
.card-img-overlay {
  transition: .5s ease;
  opacity: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  position: absolute;
  top: 60%;
  left: 50%;
  text-align: center;
  height: 80%;
  width: 100%;
  padding: 15px;
}
.card-footer {
  border: none;
  background-color: transparent;
} */
.separator {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 410px) {
  /* .card-img-overlay {
    padding: 2px;
  }
  .card-title {
    font-size:18px;
  } */
}
@media (max-width: 769px) {
  /* .gy-item {
    width: 50%;
    padding-left: 2px;
    padding-right: 2px;
  } */
}
@media (min-width: 768px) and (max-width: 991px) {
  /* .card-text {
    font-size: 18px;
  } */
}
@media (min-width: 993px) {
  /* .card img {
    margin: 0 auto;
  }
  .card:hover .card-img-overlay {
    opacity: 1;
  } */
  .subheader::before { 
    display: block; 
    content: " "; 
    margin-top: -254px; 
    height:254px; 
    visibility: hidden; 
    pointer-events: none;
  }
}
@media (max-width: 1024px) {
  /* .card-img-overlay {
    opacity: 1;
    height: 100%;
    top: 50%;
  } */
  .separator {
    margin-top: 5px;
    margin-bottom: 10px;
    min-height: 2px;
  }
}
</style>

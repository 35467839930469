import axios from 'axios';

export default class DataService {

  // POST requests

   async sendContactUs(data) { return await axios
      .post(`/api/send-email`, JSON.stringify(data))
      .then(response => {
        return response
      })
      .catch(error => { throw error });
  }

}
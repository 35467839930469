import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import error404 from '@/components/404'
import Team from '@/components/Team/Team'
import Portfolio from '@/components/Portfolio/Portfolio'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { path: '/',  name: 'Home', component: Home },
    { path: '/team', name: 'team', component: Team },
    { path: '/portfolio', name: 'portfolio', component: Portfolio },
    { path: '*', name: 'error-404', component: error404 }
  ]
})

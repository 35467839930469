import Vue from 'vue'
import { BootstrapVue, BIcon, BIconGeoAltFill, BIconChevronDoubleRight, BIconChevronDoubleLeft, BIconLinkedin, BootstrapVueIcons } from 'bootstrap-vue'
import vueHeadful from 'vue-headful'
import App from './App.vue'
import router from './router'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.use(BootstrapVueIcons)
Vue.component('BIconGeoAltFill', BIconGeoAltFill)
Vue.component('BIconChevronDoubleRight', BIconChevronDoubleRight)
Vue.component('BIconChevronDoubleLeft', BIconChevronDoubleLeft)
Vue.component('BIconLinkedin', BIconLinkedin)
Vue.component('vue-headful', vueHeadful)

new Vue({ el: '#app', router, render: h => h(App) })

/*new Vue({
  render: h => h(App),
}).$mount('#app')*/

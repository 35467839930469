<template>
  <section class="team-section">
    <vue-headful title="Our Portfolio | Goodyear Ventures" url="https://www.goodyearventures.com/portfolio" description="A team that puts entrepreneurs first. Learn more about the leaders behind Goodyear Ventures."></vue-headful>
    <div class="page-background">
        <div class="page-background-overlay"></div>
        <div class="team-header-wrapper">
            <h1 class="team-page-header1">Our <strong>Portfolio</strong></h1>
        </div>
    </div>
    <b-container fluid class="main-section">
      <b-row class="team-desc">
        <b-col sm="12">
          <h2 class="subheader gy-border">
            Goodyear Ventures <strong>Investment Portfolio</strong>
          </h2>
        </b-col>
        <b-col class="team-paragraph-wrapper" cols="12">
          <p id="team" class="team-paragraph">
            Goodyear Ventures is forming strategic relationships with innovative 
            startups that are enabling mobility across the globe. As we build an 
            investment portfolio of future-looking technology companies, we seek 
            opportunities to collaborate with startups and learn about their 
            experiences. Explore the Goodyear Ventures portfolio below to learn more.
          </p>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <PortfolioCompanies />
      </b-row>
    </b-container>
    <img class="wingfoot" src="../../assets/images/goodyear-wingfoot.svg" alt="Goodyear Wingfoot icon"/>
    </section>
</template>

<script>
import PortfolioCompanies from './PortfolioCompanies.vue';
window.dataLayer = window.dataLayer || []

export default {
  components: { PortfolioCompanies },
  name: 'Portfolio'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-background-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
}
.page-background {
  /*background-position: 10% 50%;*/
  background-size: cover;
  background-repeat: no-repeat;
  /*position: fixed;*/
  position: absolute;
  top: 0;
  bottom: 0;
  /*left: 0;*/
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('../../assets/images/background/portfolio-page-background.jpg');
  background-position: top center;
}
.team-header-wrapper {
  margin-top: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
}
.team-page-header1 {
  z-index: 100;
  color: white;
  font-size: 56px;
  font-family: "Barlow Light", Arial, sans-serif !important;

  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: black 4px solid;
  border-image-source: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(252, 238, 33, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(252, 238, 33, 1), rgba(241, 90, 38, 1));
  border-image-slice: 1;
}
.wingfoot {
  height: 65px;
  position: absolute;
  left: 48%;
  top: calc(100vh - 38px);
}
.team-section {
  margin-bottom: 100px;
}
.main-section {
  margin-top: 100vh;
}
.team-desc {
  margin-bottom: 100px;
}
.team-paragraph-wrapper {
  max-width: 750px;
  margin: auto;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}
.team-paragraph {
  line-height: 28px;
  text-align: center;
}
.subheader {
  font-size: 32px;
  text-transform: capitalize;
  text-align: center;
  max-width: 565px;
  margin: auto;
  padding-bottom: 10px;
}
.card {
  display: flex;
  max-width: 300px;
  height: auto;
  border: none;
  margin: 0 auto;
  margin-bottom: 80px;
  box-shadow: 5px 10px 18px rgb(0,0,0, .2);
  -webkit-box-shadow: 5px 10px 18px rgb(0,0,0, .2);
}
.card-text {
  height: 75px;
}
.card img.card-img-top {
  width: 260px;
  height: 260px;
  margin-left: 1rem;
  border-radius: 0px;
  margin: 20px;
}
.card .card-body {
  padding: 1rem;
  text-align: center;
 /* margin: 0 auto;*/
}
.card .card-title {
  font-weight: bold;
}
.modal-toggle {
  font-family: "Barlow Bold", Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  float: right;
  background-color: rgb(241, 90, 38);
  color: white;
  padding: 5px;
  width: 110px;
  border-radius: 30px;
  margin-bottom: 50px;
  transition: 0.2s
}
.btn.gy-primary {
  color: #000;
  text-transform: none;
  min-width: auto;
  width: 100%;
  justify-content:baseline;
}
br {
  display: none;
}

.modal-wrapper-open, .modal-wrapper-closed {
  /* display: none; */
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;

  animation-duration: 0.25s;
}

@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slidout {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes open {
  0% {display:none;}
  1% {display:block;}
  100% {display:block;}
}

/* @keyframes close {
  0% {display:block;}
  1% {display:none;}
  100% {display: none;}
} */

.modal-wrapper-open {
  /* display: block;
  opacity: 1; */
  animation-name: open;
}

.modal-wrapper-closed {
  display: none;
  /* animation-name: close;
  animation-duration: 2s;
  animation-iteration-count: 1; */
}

.modal-background {
  display: block;
  z-index: -1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.closeBtn {
  z-index: 50;
  color: white;
  width: 2em;
  margin-left: auto;
  margin-right: 20px;
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transform: translateY(20px);
}

.closeBtn:hover {
  cursor: pointer;
  opacity: 0.5;
}

.close-icon {
  font-size: 1rem;
}

.modal-inner {
  margin: auto;
  background-color: white;
  overflow: scroll;
}

.modal-inner-open {
  animation-name: slidein;
  animation-duration: 0.3s;
}

.modal-inner-closed {
  animation-name: slideout;
  animation-duration: 0.3s;
}

.modalContent-wrapper {
  /* margin-top: 30px; */
  padding: 30px;
}

.bioImg {
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  max-width: 100%;
  max-height: 100%;
}

.bioImg-ErinSpring {
  background-image: url('../../assets/images/contacts/erin-spring.png');
}

.bioImg-AbhijitGanguly {
  background-image: url('../../assets/images/contacts/abhijit-ganguly.jpg');
}


.bioImg-ChrisVarley {
  background-image: url('../../assets/images/contacts/chris-varley.jpg');
}


.bioImg-wapper {
  width: 100%;
  height: 100%;
}

.bioHeader {
  font-size: 24px;
  font-weight: 600;
}

.bioSubheader {
  margin-bottom: 30px;
  padding-bottom: 5px;
}

.bio-hr {
  margin-bottom: 30px;
}

.bioText {
  font-size: 18px;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal-inner {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }
  .bioSubheader {
    margin: auto;
    margin-bottom: 30px;
  }
  .bioImg-wapper {
    margin-bottom: 50px;
  }
  .bioImg {
    margin: auto;
  }
  .modal-background {
    display: none;
  }
    .page-background {
        background-image: url('../../assets/images/background/portfolio-page-background-xs.jpg');
        background-position: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal-inner {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }
  .bioImg-wapper {
    margin-bottom: 50px;
  }
  .bioImg {
    margin: auto;
  }
  .page-background {
        background-image: url('../../assets/images/background/portfolio-page-background-sm.jpg');
        background-position: center;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card img.card-img-top {
    max-width: 100%;
  }
  .modal-toggle:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  .modal-inner {
    margin: auto;
    margin-top: 7.5%;
    height: 80%;
    width: 90%;
  }
  .card-body {
    text-align: left;
  }
  .bioSubheader {
    margin: 0;
    margin-bottom: 30px;
  }
  .main-section {
    max-width: 1200px;
  }
  .modal-background {
    display: block;
  }
  .page-background {
        background-image: url('../../assets/images/background/portfolio-page-background-md.jpg');
        background-position: center;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  br {
    display: inline;
  }
  .modal-inner {
    margin: auto;
    margin-top: 7.5%;
    height: 70%;
    width: 70%;
  }
    .page-background {
        background-image: url('../../assets/images/background/portfolio-page-background-lg.jpg');
        background-position: center;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>

<template>
  <div class="Footer global-footer">
    <footer>
      <div class="wrapper-inner">
        <b-container>
          <b-row>
            <b-col sm="12" md="12" class="footer-desc text-center">
              <h3>We want to know what you’re up to, let’s talk!</h3>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col sm="12" md="4" lg="5" class="locations d-none d-sm-none d-md-block">
              <h5>Goodyear Innovation Centers and Labs </h5>
              <ul>
                <li v-for="lab in labs" :key="lab.location"><b-icon-geo-alt-fill></b-icon-geo-alt-fill> {{lab.location}}</li>
              </ul>
            </b-col>
          
            <b-col id="contact" sm="12" md="8" lg="7" class="">
              <b-form id="enterSweep" class="enterSweep" name="enterSweep" @submit="submitContact" v-if="showForm">
                <div class="form-header">
                  <p>All fields are required. </p>
                </div>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="firstName">First name</label>
                  <b-form-input v-model="form.firstName" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="lastName">Last name</label>
                  <b-form-input v-model="form.lastName" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="company">Company</label>
                  <b-form-input v-model="form.company" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="email">Email</label>
                  <b-form-input v-model="form.email" type="email" required></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-6">
                  <label for="iam">I am</label>
                  <b-form-select v-model="form.iam" :options="options" required></b-form-select>
                </b-form-group>

                <b-form-group class="col-sm-12 col-md-12">
                  <label for="message">Message</label>
                  <b-form-textarea v-model="form.message" placeholder="" required></b-form-textarea>
                </b-form-group>

                <b-form-group  class="form-buttons col-sm-12">
                  <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" sitekey="6Le20FYcAAAAANg7H-BQKTdEJCyGbYSEzaP67dk8" ></vue-recaptcha>
                  <p class="red">{{this.invalidField}}</p>
                  <button type="submit" class="btn gy-primary" v-if="!loading">Contact Us</button>
                  <b-spinner label="Loading..." v-if="loading"></b-spinner>
                </b-form-group >
              </b-form>

              <div class="success" v-if="showThanks && !showForm">
                <h5>Thank you for your message!</h5>
                <p>A Goodyear Ventures representative will be in touch. </p>
              </div>

              <div class="fail" v-if="showError && !showForm">
                <h5>Error</h5>
                <p>Please try again later.</p>
                <p hidden>{{returnedError}}</p>
              </div>
            </b-col>

          </b-row>
          <b-row class="footer-end">
            <b-col sm="12" class="locations d-block d-sm-block d-md-none">
              <h5>Goodyear Innovation Centers and Labs </h5>
              <ul>
                <li v-for="lab in labs" :key="lab.location"><b-icon-geo-alt-fill></b-icon-geo-alt-fill> {{lab.location}}</li>
              </ul>
            </b-col>
            <b-col sm="12" md="4" lg="5" class="footer-logo">
              <img src="../assets/images/goodyear-ventures-logo-white.png" alt="Goodyear Ventures logo"/>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="terms">
              <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
              <a class="footLink" href="https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy.html" target="_blank">Terms & Conditions and Privacy Policy</a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </footer>
  </div>
</template>

<script>
//import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

import DataService from "../services/data.service";
const dataService = new DataService();

window.dataLayer = window.dataLayer || []
export default {
  name: 'Footer',
  data() {
      return {
        form: {
          firstName: '',
          lastName: '',
          email: '',
          iam: '',
          message: '',
          company: '',
          gResponse: '',
        },
        returnedError: '',
        showForm: true,
        invalidField: ' ',
        showThanks: false,
        showError: false,
        loading: false,
        options: [
          /*{ value: null, text: 'Please select an option' },*/
          { value: 'An Entrepreneur', text: 'An Entrepreneur' },
          { value: 'Press / Analyst', text: 'Press / Analyst' },
          { value: 'Venture Capitalist', text: 'Venture Capitalist' },
          { value: 'Other', text: 'Other' }
        ],
        labs: [
          { location: 'Akron, OH, USA' },
          { location: 'San Francisco, CA, USA' },
          { location: 'Colmar-Berg, Luxembourg' },
          { location: 'Shanghai, China' }
        ]
      }
    },
    components : {
      VueRecaptcha
    },
    methods : {
      onVerify: function (response) {
        if (response) this.form.gResponse = response
      },
      submitContact (evt) {
        evt.preventDefault()
        this.loading = true
        this.invalidField = ''

        // recaptcha
        if (this.form.gResponse.length == 0) {
            // reCaptcha not verified
            //console.log('recaptcha not verified!')
            this.loading = false
            this.invalidField = 'Please verify using reCAPTCHA.'
            return false;
        }
        

            dataService.sendContactUs(this.form)
              .then(response => {
                //console.log(response.data)
                this.loading = false
                this.showForm = false
                if (response.status == 200 && response.data.code == 0) {
                  this.showThanks = true
                  window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Contact Us' })
                } else {
                  this.showError = true
                  this.returnedError = response.data.message;
                }
              })
              .catch(function (error) {
                console.log(error);
              });	
          
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background-color: #000;
  /*background: #000;*/
  background-image: url('../assets/images/background/highways-md.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  color: #fff;
  min-height: 300px;
}
.wrapper-inner {
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
h3 {
  color: #fff;
  text-transform: none;
  letter-spacing: 1px;
}
a, .v-btn {
  color: #fff;
}
a {
  text-decoration: underline;
}
a:hover {
  color: #F9ED25;
}
ul {
  list-style: none;
}
ul li {
  padding: 5px 0px;
}
li .b-icon.bi {
  height: 20px;
  width: 20px;
}
.form-group {
  float: left;
}
.form-control, .custom-select {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 3px solid;
  border-image-source: -webkit-linear-gradient(45deg, rgba(249, 237, 37, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(45deg, rgb(249, 237, 37), rgb(241, 90, 38));
  border-image-slice: 1;
  /*background-color: transparent;
  color: #fff;*/
  border-radius: 0px;
}
.success, .fail {
  font-style: italic;
}
.success h5 {
  color: #F9ED25;
  font-family: "Barlow Bold", Arial, sans-serif;
  font-weight: 700;
}
.fail h5 {
  color: red;
  font-family: "Barlow Bold", Arial, sans-serif;
  font-weight: bold;
}
.footer-logo img {
  width: 300px;
  margin-bottom: 15px;
}
.footer-end {
  margin-top: 35px;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 500px) {
  footer {
    background-image: url('../assets/images/background/highways-xs.jpg');
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  footer {
    background-image: url('../assets/images/background/highways-sm.jpg');
  }
}
@media (max-width: 768px) {
  .footer-logo img {
    width: 60%;
    margin: 30px 0px 60px;
  }
  .terms {
    text-align: center;
  }
  .locations {
    max-width: 400px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .footer-desc {
    margin-top: 12px;
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .footer-end {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 110px;
  }
  .terms {
    text-align: right;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .footer-logo img {
    width: 99%;
  }
}
@media (max-width: 992px) {
  .footer-logo {
    text-align: center;
  }
}
@media (min-width: 980px) {
  .footer-desc {
      padding-left: 40px;
      margin-bottom: 50px;
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  footer {
    background-image: url('../assets/images/background/highways-lg.jpg');
  }
}
@media (min-width: 1601px) {
  footer {
    background-image: url('../assets/images/background/highways.jpg');
  }
}
</style>

<template>
  <div class="global-header mt-4">
    <b-navbar toggleable="lg" is-nav>
      <b-container fluid id="gynav">
        <b-navbar-brand class="gohome d-sm-block d-md-block d-lg-none" href="/"><img class="logo" src="../assets/images/goodyear-ventures-logo-white.png" alt="Goodyear Ventures logo"/></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav main">
            <li id="home-nav-link" class="nav-item"><a class="nav-link" href="/">Home</a></li>
            <li id="about-nav-link" :class="['nav-item']"><a class="nav-link" href="/#about">About</a></li>
            <li id="focus-nav-link" class="nav-item"><a class="nav-link" href="/#focus">Focus</a></li>
            <li id="portfolio-nav-link" class="nav-item"><a class="nav-link" href="/#portfolio">Portfolio</a></li>
            <li id="news-nav-link" class="nav-item"><a class="nav-link" href="/#news">News</a></li>
            <li id="team-nav-link" class="nav-item"><a class="nav-link" href="/#team">Team</a></li>
          </ul>
          <div class="navbar-nav d-none d-sm-none d-md-none d-lg-block mx-auto">
            <b-nav-text>
              <a class="gohome" href="/"><img class="logo navbar-logo" src="../assets/images/goodyear-ventures-logo-white.png" alt="Goodyear Ventures logo"/></a>
            </b-nav-text>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="btn gy-primary" href="#contact">
                Contact Us
              </a>
            </li>
          </ul>
        </b-collapse>

        
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
    })
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth

      // small screen
      if (this.windowWidth <= 1200) {
        // console.log('smaller screen detected!')
        document.getElementById('gynav').classList.add('container-fluid')
        document.getElementById('gynav').classList.remove('container')
      } else {
        // console.log('md-lg screen detected!')
        document.getElementById('gynav').classList.add('container')
        document.getElementById('gynav').classList.remove('container-fluid')
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  color: #fff;
  padding: .2rem .2rem .2rem 1rem;
  z-index: 3;
}
.global-header :focus {
  outline: none;
}
.navbar-brand {
  font-family: "Barlow Light", Arial, sans-serif;
}
.navbar .navbar-nav .nav-item a.nav-link {
  color: #fff;
  font-size: 18px;
  font-family: "Barlow Light", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: .65;
}
.navbar-nav .nav-item:hover, .navbar-nav .nav-item:hover a.nav-link, .navbar-nav .nav-item a.nav-link:hover {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-image-source: -webkit-linear-gradient(45deg, rgba(102, 102, 99, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(45deg, rgb(249, 237, 37), rgb(241, 90, 38));
  border-image-slice: 1;
  cursor: pointer;
  color: #fff;
  opacity: 1;
}
.logo {
  height: 60px;
  width: auto;
  padding-left: 2%;
}
.navbar-nav .nav-item .nav-link:focus {
  color: #fff;
  border: none;
}
.navbar .navbar-toggler {
  background-color: rgba(255, 255, 255, .9);
  border-radius: 0px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  height: 1em;
  width: 1em;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;
}
ul {
  list-style: none;
  padding-inline-start: 0px;
}

#portfolio-nav-link:hover #portfolio-dropdown {
  display: block;
}

#team-nav-link:hover #team-dropdown {
  display: block;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: white;
  border-top: 6px solid;
  border-image-source: -webkit-linear-gradient(138deg, rgba(249, 237, 37, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(138deg, rgb(249, 237, 37), rgb(241, 90, 38));
  border-image-slice: 1;
  color: black;
  font-size: 0.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px 30px;
}

.dropdown li {
  color: black;
  text-decoration: none;
  margin-bottom: 20px;

  transition: 0.2s;
}
.dropdown a {
  color: black;
  text-decoration: none;
}
.dropdown li:last-child {
  margin-bottom: 0px;
}
.dropdown li:hover {
  font-weight: 600;
  transform: translateX(5px);
}

@media (max-width: 445px) {
  .navbar .navbar-nav .nav-item a.nav-link, .gy-primary.btn {
    text-align: center;
  }
}
@media (min-width: 445px) and (max-width: 1150px) {
  .btn {
    min-width: auto;
    padding: 5px 10px;
  }
}
@media (max-width: 767px) {
  .logo {
    padding: 5px 2px 5px 2%;
    height: 55px;
  }
  .navbar-toggler-icon {
    height: 1em;
    width: 1em;
  }
}
@media (max-width: 991px) {
  .navbar {
    padding: .2rem .2rem .2rem .2rem;
  }
  .navbar #nav-collapse {
    background-color: #000;
    padding: .2rem .2rem .2rem 1rem;
    /*position: absolute;
    z-index: 1;*/
  }
  .navbar .navbar-nav .nav-item a.nav-link {
    opacity: 1;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1.5px solid;
    border-image-source: -webkit-linear-gradient(138deg, rgba(249, 237, 37, 1), rgba(241, 90, 38, 1));
    border-image-source: linear-gradient(138deg, rgb(249, 237, 37), rgb(241, 90, 38));
    border-image-slice: 1;
  }
  .gy-primary.btn {
    border: none;
    font-family: "Barlow Light", Arial, sans-serif;
    padding: 0.5rem 0;
    letter-spacing: 2px;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding: 0rem 1rem;
  }
  /*.navbar-nav {
    margin-left: 15%;
  }*/
  .navbar-nav .nav-item {
    padding: 0px 10px;
  }
  .navbar-nav .nav-item .nav-link {
    margin: 12px 0;
    padding: 3px 2px;
    border-bottom: 2px solid transparent;
  }
  .navbar-nav .nav-item a.nav-link {
    -webkit-transition : border .5s linear;
    -moz-transition : border .5s linear;
    -o-transition : border .5s linear;
    transition : border .5s linear;
  }
}

</style>

<template>
  <section id="about" class="about-section">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h2 class="subheader">About</h2>
            <br/>
            <h3>We Are Goodyear...</h3>
            <p class="subpara">looking for innovative startups to help <span class="gy-border">drive the future of mobility</span>. </p>
          </b-col>

          <b-col sm="12" md="6">
            <div class="home-promo">
              <p>For over 120 years we’ve been pushing the future of mobility forward and today we want to partner with and invest in the visionaries, the technologies and the experiences that will propel the mobility revolution forward.</p>
            </div>
          </b-col>
            
          <b-col sm="12" md="6">
            <div class="home-promo">
              <p>Goodyear Ventures fuels the future of mobility by partnering and investing in seed-to-growth-stage startups in emerging mobility technology.</p>
            </div>
          </b-col>

          <b-col sm="12">
            <div class="home-promo long">
              <h3>Our Challenge</h3>
              <p>Goodyear Ventures is helping build the future of mobility by amplifying Goodyear's legacy of innovation. We want to advance the future of transport around the world in ways that dramatically increase safety, sustainability, and make life’s connections easier everyday.</p>
            </div>
          </b-col>

        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-section {
  text-align: center;
  /*border-top: 1px solid red;*/
}
.about-section h3 {
  margin-bottom: 0.25rem;
}
.about-section .home-promo.long {
  margin-bottom: 25px;
  margin-top: 2px;
}

.home-promo {
  text-align: left;
  padding: 15px 15px 5px 15px;
 /* border: 1px solid red;*/
  margin-bottom: 15px;
  /*min-height: 275px;*/
}
.home-promo.long {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 75px;
}
.home-promo h3 {
  margin-bottom: 15px;
}

.subheader{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}

.subpara {
  font-size: 18px;
  letter-spacing: 1px;
}

.home-promo h3 {
  margin-bottom: 15px;
}

.container {
  padding: 24px;
}

@media (min-width:992px) {
  .home-promo.long {
    width: 880px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media (max-width:992px) {
  .subheader::before { 
    display: block; 
    content: " "; 
    margin-top: -254px; 
    height:254px; 
    visibility: hidden; 
    pointer-events: none;
  }
}
</style>
